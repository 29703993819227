import React, { Component } from 'react';

export default class IsRead extends Component {

    articleIsRead(user, article) {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id.split("_")[0] === article._id.split("_")[0] && articleObj.readed);
            });
        }
    }

    setRead = (article) => {
        if(this.props.isCouverture)
            return this.props.setArticleReaded(article, this.props.typeData, this.props.numero)
    };

    unsetRead = (article) => {
        if(this.props.isCouverture)
            return this.props.unsetArticleReaded(article)
    };

    render() {

        const { article, fonts, user, isCouverture } = this.props;

        if(this.articleIsRead(user, article)){
            return(
                <div className="status"
                     style={ fonts.family1 } onClick={() => this.unsetRead(article) }>
                    <i className="ico-chu-nice-read" style={{ color: (article.category || article).couleur }} />
                    <span className="inner">Lu</span>
                </div>
            )
        } else {
            return(
                <div className="status"
                     style={ fonts.family1 } onClick={() => this.setRead(article) }>
                    <i className="ico-chu-nice-progress" style={{ color: (article.category || article).couleur }} />
                    <span className="inner">Non lu</span>
                </div>
            )
        }
    }

}
